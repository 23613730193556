<template>
  <div class="home">
    <div class="container">
      <v-header/>
    </div>
    <div class="section dt12 db7 mt7 mb7">
      <div class="container">
        <div class="row middle">
          <div class="d-7">
            <h1 class="db9">404 ошибка</h1>
            <p class="h4"><strong>Мы&nbsp;не&nbsp;нашли эту страницу. Возможно, мы&nbsp;её&nbsp;удалили, или ссылка не&nbsp;работает. Предлагаем вернуться <a href="javascript:history.back()">назад</a> или <a href="/">на&nbsp;главную страницу</a>.</strong></p>
            <p>Если у вас есть вопросы, напишите нам на <a href="mailto:team@ediweb.com">team@ediweb.com</a>.</p>
          </div>
          <div class="d-4 d-offset-1 m-hide middle">
            <img src="@/assets/404.svg"
              data-copyright-source="https://storyset.com/illustration/404-error-with-a-cute-animal/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const meta = {
  title: "Мы не нашли эту страницу | WorkCat",
};

export default {
  name: 'NotFound',
  components: {
    'v-header': () => import('@/components/v-header.vue'),
  },
  metaInfo: {
    title: meta.title,
    meta: [
      {
        name: "og:title",
        content: meta.title,
      },
      {
        name: "robots",
        content: "none",
      },
    ],
  },
}
</script>
